<template>
  <div class="adviser_page">
    <div class="title">
      <div class="text">官方声明：</div>
      <div class="text1">
        作为Prefootball.cn网站（暨全国足球特色幼儿园系统平台）运营单位，小牛启航体育（北京）有限公司（统一社会信用代码911101166766387336）是目前唯一的官方运营单位，在全国没有任何指定机构，没有任何合作代理商及机构。
      </div>
      <div class="text2">特此声明!</div>
      <div class="text3">2023年01月20日</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import chinaJson from "@/assets/js/china.json";
export default {
  name: "adviserBlock",
  components: {},
  data() {
    return {
      geoCoordMap: {
        南昌: [115.89, 28.68],
        新疆: [86.61, 40.79],
        西藏: [89.13, 30.66],
        黑龙江: [128.34, 47.05],
        吉林: [126.32, 43.38],
        辽宁: [123.42, 41.29],
        内蒙古: [112.17, 42.81],
        北京: [116.4, 40.4],
        宁夏: [106.27, 36.76],
        山西: [111.95, 37.65],
        河北: [115.21, 38.44],
        天津: [117.04, 39.52],
        青海: [97.07, 35.62],
        甘肃: [103.82, 36.05],
        山东: [118.01, 36.37],
        陕西: [108.94, 34.46],
        河南: [113.46, 34.25],
        安徽: [117.28, 31.86],
        江苏: [120.26, 32.54],
        上海: [121.46, 31.28],
        四川: [103.36, 30.65],
        湖北: [112.29, 30.98],
        浙江: [120.15, 29.28],
        重庆: [107.51, 29.63],
        湖南: [112.08, 27.79],
        江西: [115.89, 27.97],
        贵州: [106.91, 26.67],
        福建: [118.31, 26.07],
        云南: [101.71, 24.84],
        台湾: [121.01, 23.54],
        广西: [108.67, 23.68],
        广东: [113.98, 22.82],
        海南: [110.03, 19.33],
        澳门: [113.54, 22.19],
        香港: [114.17, 22.32],
      },
      providerList: [
        {
          name: "内蒙古小巨星儿童体育发展有限公司",
          person: "联系人：茫来",
          tel: "联系电话：15704863223",
          address: "服务地区:内蒙古/鄂尔多斯市",
        },
        {
          name: "上饶市杰德体育文化发展有限公司",
          person: "联系人：黄杰",
          tel: "联系电话：13699575235",
          address: "服务地区:江西省/市辖区/南昌市",
        },
        {
          name: "滨州亿达文化商贸有限公司",
          person: "联系人：赵南",
          tel: "联系电话：18905437801",
          address: "服务地区:山东省/市辖区/滨州市",
        },
        {
          name: "辰跃体育",
          person: "联系人：张梁",
          tel: "联系电话：15023222632",
          address: "服务地区:重庆市/云南省/昆明市",
        },
        {
          name: "安徽采石匠教育科技有限公司",
          person: "联系人：黄福龙",
          tel: "联系电话：15712883888",
          address: "",
        },
        {
          name: "深圳龙熙广告有限公司",
          person: "联系人：龙娟",
          tel: "联系电话：15986645180",
          address: "",
        },
        {
          name: "深圳市学智成文化发展有限公司",
          person: "联系人：宋媛媛",
          tel: "联系电话：13823598866",
          address: "",
        },
        {
          name: "广东顺德大悦环境科技有限公司",
          person: "联系人：吴春雷",
          tel: "联系电话：18676512886",
          address: "",
        },
        {
          name: "茂名市小马哥信息科技有限公司",
          person: "联系人：陈国光",
          tel: "联系电话：17303030921",
          address: "",
        },
        {
          name: "中山市中安信息科技有限公司",
          person: "联系人：周伟明",
          tel: "联系电话：13790735023",
          address: "",
        },
        {
          name: "新疆格瑞斯网络科技有限公司",
          person: "联系人： 刘江",
          tel: "联系电话：15309918777",
          address: "",
        },
        {
          name: "乌鲁木齐市凌禾尚科信息科技有限公司",
          person: "联系人： 高志玺",
          tel: "联系电话：15609917074",
          address: "",
        },
        {
          name: "弗特堡（上海）足球俱乐部有限公司",
          person: "联系人：赵国佑",
          tel: "联系电话：18918293944",
          address: "",
        },
        {
          name: "河北零壹体育文化传播有限公司",
          person: "联系人：刘楠",
          tel: "联系电话：13286541514",
          address: "",
        },
        {
          name: "河南豫发会展有限公司",
          person: "联系人： 毋轲",
          tel: "联系电话：13938557272",
          address: "",
        },
        {
          name: "河南小叮当企业咨询管理有限公司",
          person: "联系人： 邱定华",
          tel: "联系电话：13903816318",
          address: "",
        },
        {
          name: "北京英宏鼎盛商贸有限公司",
          person: "联系人： 沈凌云",
          tel: "联系电话：13810640066",
          address: "",
        },
      ],
    };
  },
  mounted() {
    // this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      echarts.registerMap("china", chinaJson);
      var myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: true,
          trigger: "item",
          triggerOn: "click",
          enterable: true,
        },
        legend: {
          show: true,
          type: "plain",
          // left: 'left',
          // zlevel: 1,
          // formatter: '{name}',
        },
        geo: {
          map: "china",
          roam: false, // 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
              textStyle: {
                color: "#fff",
              },
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            geoIndex: 1,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: true, // 存在legend时显示
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff",
                },
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 1,
              },
              emphasis: {
                areaColor: "#fff",
              },
            },
            select: {
              label: {
                show: false,
              },
              itemStyle: {
                color: "#fff",
              },
            },
            data: [],
            tooltip: {
              show: false,
            },
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.convertData([
              { name: "上海", value: 0 },
              { name: "南昌", value: 0 },
              { name: "新疆", value: 0 },
              { name: "河北", value: 0 },
              { name: "河南", value: 0 },
              { name: "陕西", value: 0 },
              { name: "山东", value: 0 },
              { name: "重庆", value: 0 },
              { name: "安徽", value: 0 },
              { name: "广东", value: 0 },
            ]),
            symbolSize: 10,
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#f4e925",
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            zlevel: 1,
            tooltip: {},
          },
        ],
      });
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.adviser_page {
  .title {
    width: 65%;
    background-color: #cc1a30;
    margin: 0 auto;
    height: 860px;
    padding-left: 76px;
    padding-right: 76px;
    font-weight: bold;
    text-align: left;
    display: flex;
    flex-direction: column;
    .text {
      color: #fcfefb;
      font-size: 40px;
      margin-top: 50px;
    }
    .text1 {
      color: #fcfefb;
      font-size: 40px;
      margin-top: 50px;
      text-indent: 2em;
    }
    .text2 {
      color: #fcfefb;
      font-size: 40px;
      margin-top: 50px;
      text-indent: 2em;
    }
    .text3 {
      color: #fcfefb;
      font-size: 40px;
      text-align: right;
    }
  }
}
</style>
